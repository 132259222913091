@import '~react-responsive-audio-player/dist/audioplayer.css';
html,
body {
  min-height: 100% !important;
  height: 100%;
  width: 100%;
  margin: 0;
  min-width: 320px;
  padding: 0 !important;
}

body {
  font-family: 'Montserrat-Regular', sans-serif !important;
  color: #212121;
  background-color: #ffffff !important;
  /* overflow: auto !important; */
}

@font-face {
  font-family: Montserrat-Light;
  src: url('../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: Montserrat-Regular;
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/Montserrat-Bold.ttf');
}

/* Form Elements */

.form-control.MuiFormControl-root {
  display: block;
  margin-bottom: 32px;
}

.form-control.MuiFormControl-root
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.form-control.MuiFormControl-root
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff;
}

.form-control.MuiFormControl-root
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff;
}

.form-control.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  color: #ffffff;
  font-size: 16px;
}

.form-control.MuiFormControl-root .MuiFormLabel-root {
  color: #ffffff;
}
.form-control.MuiFormControl-root .MuiInputBase-root {
  width: 100%;
}

.form-control.MuiFormControl-root
  .MuiInputBase-root
  textarea.MuiInputBase-input {
  height: 63px;
}

.form-control.MuiFormControl-root .MuiInputBase-root .MuiSelect-select:focus {
  background: none !important;
}

.form-control.MuiFormControl-root .MuiSelect-icon {
  /* background: url('../chevron-down-dark.svg') no-repeat center; */
  background-size: 16px 16px;
  transition: all 0.2s ease-in-out;
}

.form-control.MuiFormControl-root .MuiSelect-icon path {
  display: none;
}

.form-control.MuiFormControl-root .MuiSelect-selectMenu {
  min-height: auto;
}

.form-control.MuiAutocomplete-root {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.form-control.MuiFormControl-root
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.form-control.MuiFormControl-root
  .MuiFormControl-root
  .MuiAutocomplete-inputRoot {
  padding: 0px !important;
}

.autocomplete-control.MuiAutocomplete-root
  .MuiFormControl-root
  .MuiFormHelperText-root {
  font-size: 10px;
  line-height: 17px;
  color: #878181;
  margin: 5px 0 0;
  padding: 0;
}

.autocomplete-control
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
  .MuiAutocomplete-input {
  padding: 8.5px 4px !important;
}

.autocomplete-control.MuiAutocomplete-root
  .MuiFormControl-root
  .MuiFormHelperText-root {
  font-size: 12px;
  line-height: 16px;
  margin-top: 3px;
}

.native-datepicker.form-control.MuiFormControl-root
  .MuiInputBase-root
  .MuiInputBase-input {
  /* background: url('../calendar-primary.svg') no-repeat right 13px center; */
  background-size: 18px 18px;
  padding-right: 40px;
}

.MuiFormControl-root.form-control.with-outlined-button
  .MuiOutlinedInput-adornedEnd {
  padding-right: 10px;
}

.MuiFormControl-root.form-control.with-outlined-button
  .MuiOutlinedInput-adornedEnd
  .MuiInputAdornment-root
  .upload-outline-btn
  .MuiButton-outlinedPrimary {
  padding: 7px 14px !important;
}

/* Button */
.btn.MuiButton-root {
  font-weight: 600;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  letter-spacing: 0;
  font-size: 12px;
  padding: 11px 20px;
  line-height: 18px;
  box-shadow: none !important;
  border-radius: 6px;
  color: #fff !important;
}

.MuiButton-containedPrimary {
  /* background-image: linear-gradient(to bottom, #f47c17, #f44a22) !important; */
  /* border-color: transparent !important; */
  background-color: #2b2b2b !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #ffffff !important;
}
.MuiButton-containedSecondary:hover {
  background-color: #ffffff !important;
}
.MuiButton-text:hover {
  /* background-image: linear-gradient(to bottom, #f47c17, #f44a22 99%) !important; */
  color: #fff !important;
}
/* .MuiButton-outlinedPrimary{ border: 0!important; padding: 2px!important; border-radius: 6px!important;background-image: linear-gradient(106deg, #302d89, #0b65b2 99%)!important; overflow: hidden; }
.MuiButton-outlinedPrimary .MuiButton-label{ width: auto; font-family: ProximaNova-Semibold; font-weight: 600; background: #FFF; padding: 6px 20px; border-radius: 4px!important; display: flex; justify-content: flex-start; align-items: center; }
.MuiButton-outlinedPrimary:hover .MuiButton-label{ background-image: linear-gradient(106deg, #302d89, #0b65b2 99%)!important; color: #FFF!important;  } */

.MuiButton-outlinedPrimary {
  border: 0 !important;
  padding: 11px 20px !important;
  border-radius: 6px !important;
  border: 1px solid #979797 !important;
  overflow: hidden;
}

.MuiButton-outlinedPrimary .MuiButton-label {
  font-family: Montserrat-SemiBold;
  font-weight: 600;
  /* color: #2b2b2b; */
}

.MuiButton-outlinedPrimary:hover {
  background: #ffffff !important;
}

.MuiButton-outlinedPrimary:hover .MuiButton-label {
  color: #fff;
  font-family: Montserrat-Regular;
}

.btn-iconic-text {
  display: flex;
}

.btn-iconic-text > span,
.btn-iconic-text img {
  display: inline-block;
  vertical-align: middle;
  font-family: Montserrat-SemiBold;
  font-weight: 600;
}

.btn-iconic-text img {
  height: 18px;
  width: 18px;
}

.btn-iconic-text .MuiButton-startIcon {
  margin-right: 4px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-iconic-text .MuiButton-startIcon svg {
  font-size: 16px;
}

.btn-iconic-text .MuiButton-label span {
  display: inline-block;
  vertical-align: middle;
  font-family: Montserrat-SemiBold;
  font-weight: 600;
}

.btn-lg {
  height: 50px;
  padding: 12px 38px !important;
}

.btn-lg .MuiButton-label {
  font-size: 14px;
  line-height: 18px;
}

.btn-sm {
  height: 34px;
  padding: 7px 14px !important;
}

.btn-sm .MuiButton-label {
  font-size: 12px;
  line-height: 16px;
}
.kep-login-facebook {
  width: 44.5px !important;
  height: 48px !important;
  padding: 0 !important;
  background-color: white !important;
  border: none !important;
}
.MuiMenu-paper {
  backdrop-filter: blur(17.2px) !important;
  border: solid 1px rgba(255, 255, 255, 0) !important;
  background-color: black !important;
  opacity: 0.8 !important;
  margin-top: 41px !important;
  border-radius: 10px !important;
}
/* .MuiPopover-root{
transform:translate3d(0, 0, 0) !important;
-webkit-overflow-scrolling: auto !important
} */

.scroll-menu-arrow {
  cursor: pointer !important;
}

.scroll-menu-arrow--disabled {
  visibility: hidden !important;
}
.menu-wrapper {
  margin-left: 1rem;
  margin-right: 1rem;
}





